import React from "react";
import MainLayout from "../Layouts/MainLayout";
import ContactForm from "../Components/ContactForm";
 



function Contactos() {
  return (
    <MainLayout>
      <div>
        <ContactForm />
      </div>
      
    </MainLayout>
  );
}

export default Contactos;