import React from "react";
import MainLayout from "../Layouts/MainLayout";
import SobreNos from "../Components/SobreNos";




function Sobre() {
  return (
    <MainLayout>
      <div>
        <SobreNos />
      </div>
      
    </MainLayout>
  );
}

export default Sobre;