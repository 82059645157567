import React from "react";
import "./Footer.css"


function Footer() {
  return (
    <div className="footer">Copyright Augusto Fonte. All Rights Reserved</div>
  );
}

export default Footer;